import React from "react"
import { Col } from "react-bootstrap"
import Img from "gatsby-image"

export const FeatureImageHome = ({ feature }) => {
  return (
    <Col key={feature.id} md={4}>
      <div className="display-3 font-weight-bold">{feature.title}</div>

      {feature.body && (
        <div
          className="lead my-3"
          dangerouslySetInnerHTML={{
            __html: feature.body.childMarkdownRemark.html,
          }}
        />
      )}

      <div className="my-5">
        <Img fixed={feature.image.fixed} alt={feature.image.title} />
      </div>
    </Col>
  )
}
