import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PropertyGrid } from "../components/Property/PropertyGrid"
import { Hero } from "../components/Hero/Hero"
import { Container, Row, Col, Button } from "react-bootstrap"
import { HR } from "../components/Typography/Seperators"
import { graphql, Link } from "gatsby"
import {
  CurveCarveTop,
  CurveCarveBottom,
  CurveBulgeBottom,
} from "../components/Curves/Curve"
import { Newsletter } from "../components/Misc/Newsletter"
import { FeatureImageHome } from "../components/Feature/FeatureImageHome"
import { AccordionCollection } from "../components/Accordion/AccordionCollection"
import { ArticleSnippetHome } from "../components/Article/ArticleSnippetHome"

const IndexPage = ({ data }) => {
  const { contentfulHomePage } = data

  return (
    <Layout>
      <SEO
        title={contentfulHomePage.pageTitle}
        description={contentfulHomePage.pageDescription.pageDescription}
      />

      <Hero
        title={contentfulHomePage.heroTitle}
        subtitle={contentfulHomePage.heroSubtitle}
      />
      <CurveCarveTop variant="primary" background="light" />
      <div className="bg-primary text-white text-center py-5">
        <Container>
          <Row>
            <Col>
              <h1 className="h2">{contentfulHomePage.introTitle}</h1>
              <HR />

              {contentfulHomePage.introBody && (
                <div
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html:
                      contentfulHomePage.introBody.childMarkdownRemark.html,
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveBottom variant="primary" />
      <div className="py-5 text-center">
        <Container>
          <Row>
            {contentfulHomePage.features &&
              contentfulHomePage.features.map(feature => {
                return <FeatureImageHome key={feature.id} feature={feature} />
              })}
          </Row>
        </Container>
      </div>
      <CurveCarveTop variant="light" />
      <div className="bg-light pt-5">
        <PropertyGrid
          properties={contentfulHomePage.featuredProperties}
          displayCount={6}
        />

        <Container>
          <Row>
            <Col className="text-center mb-5">
              <Button
                as={Link}
                variant="secondary"
                size="lg"
                to="/search-result"
              >
                Search Investments
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveTop variant="primary" background="light" />
      <AccordionCollection items={contentfulHomePage.accordions} />
      <CurveBulgeBottom variant="primary" background="light" />
      <div className="bg-light">
        <Container className="py-5">
          <Row>
            <Col className="text-center my-5">
              <h3>UK Property Investment News</h3>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <h4 className="text-care-home">Care Home Investments</h4>

              <ArticleSnippetHome
                triangle
                article={contentfulHomePage.featuredNewsCareHome}
                variant={"care-home"}
              />
            </Col>
            <Col md={6} lg={3}>
              <h4 className="text-student-property">
                Student Property Investments
              </h4>
              <ArticleSnippetHome
                triangle
                article={contentfulHomePage.featuredNewsStudent}
                variant={"student-property"}
              />
            </Col>
            <Col md={6} lg={3}>
              <h4 className="text-buy-to-let">
                Buy-to-let Property Investments
              </h4>
              <ArticleSnippetHome
                triangle
                article={contentfulHomePage.featuredNewsBuyToLet}
                variant={"buy-to-let"}
              />
            </Col>
            <Col md={6} lg={3}>
              <h4 className="text-primary">Property Investment Guidance</h4>
              <ArticleSnippetHome
                triangle
                article={{
                  title: "One Touch property consultants",
                  snippet: {
                    snippet: ` One Touch property consultants are an experienced team who will
                share their expert knowledge to help you take an investment
                decision with confidence and ease`,
                  },
                  permalink: "/uk-property-investment-guides-videos",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center my-5">
              <Button
                as={Link}
                variant="secondary"
                size="lg"
                to="/property-news-uk"
              >
                All News
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <CurveBulgeBottom variant="light" />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    contentfulHomePage(contentful_id: { eq: "qWUVCsnD8N0Z6vEIGgT28" }) {
      id
      contentful_id
      pageTitle
      pageDescription {
        pageDescription
      }
      heroTitle
      heroSubtitle

      introTitle
      introBody {
        childMarkdownRemark {
          html
        }
      }

      features {
        ...FeatureFragment
      }
      featuredProperties {
        ...PropertyListFragment
      }

      accordions {
        ...AccordionFragment
      }

      featuredNewsCareHome {
        id
        title
        permalink
        snippet {
          snippet
        }
      }
      featuredNewsStudent {
        id
        title
        permalink
        snippet {
          snippet
        }
      }
      featuredNewsBuyToLet {
        id
        title
        permalink
        snippet {
          snippet
        }
      }
    }
  }
`

export default IndexPage
