import React from "react"
import styled from "styled-components"
import { Button, Container, Col, Row } from "react-bootstrap"
import { CurveCarveTop } from "../Curves/Curve"
import watermark from "../../images/logo-white.svg"
import { Link } from "gatsby"
import AccreditationsImage from "./AccreditationsImage"

const StyledHeroWrapper = styled.div`
  background-color: ${({ theme }) => theme.light};
  position: relative;

  &:before {
    content: "";
    background-image: url(${watermark});
    background-repeat: no-repeat;
    background-position: 100% center;
    background-size: contain;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 15%;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  .gatsby-image-wrapper {
    max-width: 300px;
  }
`

const StyledHero = styled.div`
  position: relative;
  z-index: 2;
  padding-top: 50px;
  padding-bottom: 100px;
`

const StyledTextWrapper = styled.div`
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;

  h1 {
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 30px;
  }
`

const AccreditationsCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  /* Gatsby Image Fix */
  & > * {
    flex-grow: 1;
  }
`
const AccreditationsWrapperMobile = styled.div`
  max-width: 450px;
  margin: auto;
`

export const Hero = ({ title, subtitle }) => {
  return (
    <>
      <div className="d-lg-none ">
        <StyledTextWrapper>
          <h2>{title}</h2>

          <p className="lead">{subtitle}</p>

          <Button as={Link} size="lg" variant="secondary" to="/search-result">
            Explore Investments
          </Button>
        </StyledTextWrapper>

        <CurveCarveTop variant="light" />
        <div className="bg-light py-5 text-center">
          <AccreditationsWrapperMobile className="px-5">
            <AccreditationsImage />
          </AccreditationsWrapperMobile>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <CurveCarveTop variant="light" />
        <StyledHeroWrapper>
          <StyledHero>
            <Container fluid>
              <Row>
                <AccreditationsCol>
                  <AccreditationsImage />
                </AccreditationsCol>
                <Col md={6}>
                  <StyledTextWrapper>
                    <h2 className="h1">{title}</h2>

                    <p className="lead">{subtitle}</p>

                    <Button
                      as={Link}
                      size="lg"
                      variant="secondary"
                      to="/search-result"
                    >
                      Explore Investments
                    </Button>
                  </StyledTextWrapper>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </StyledHero>
        </StyledHeroWrapper>
      </div>
    </>
  )
}
